<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-click-outside="onClickOutside" class="container">
        <img
          alt="close"
          class="close"
          src="@/assets/icons/close-modal.svg"
          @click="onClickOutside"
        />

        <div class="title">Получение выплаты</div>
        <div class="description">
          Получение выплаты производится<br />
          в течении 7 рабочих дней.
        </div>

        <button @click="onClickOutside">Все хорошо</button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 404px;
      margin: 0px auto;
      background-color: white;
      border-radius: 34px;
      //   display: flex;
      padding: 30px;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      img.close {
        position: absolute;
        right: 30px;
        top: 30px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #3a3e46;
        margin-bottom: 15px;
      }

      .description {
        color: #423c5a;
        font-weight: 500;
        text-align: center;
        font-size: 15px;
        padding: 20px 0;
      }

      button {
        background: #0085ff;
        border-radius: 6px;
        color: white;
        padding: 15px 25px;
        margin: 15px auto auto auto;
        display: block;
        font-size: 15px;
        transition: 200ms ease;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
