<template>
  <div>
    <!-- <div class="alert">
      <div class="left">
        <span class="badge">i</span>
        <span class="title">
          Уровень партнерской программы Revshare до 25.10.2021
        </span>
      </div>
    </div> -->

    <div class="flex">
      <div class="alert">
        <div class="left">
          <span class="badge">i</span>
          <span class="title">
            Уровень партнерской программы Revshare до {{ progress.finish_at }}
          </span>
        </div>
      </div>

      <div class="box blue">
        <div class="title">{{ profit }} ₽</div>
        <div class="description">Доступно к выводу</div>
      </div>
    </div>

    <div class="main">
      <div class="content">
        <div class="progress-bar">
          <div class="title">
            Ваш следующий уровень: {{ progress.nextlvl }}%
          </div>
          <div class="description">
            Начинает действовать с {{ progress.start }}
          </div>
          <div class="progress">
            <div class="finish">{{ progress.nextchance }}</div>
            <div class="line" :style="{ width: progress.percent + '%' }">
              {{ progress.current }}
            </div>
          </div>
        </div>

        <button class="primary" @click="showModal = true">
          Получение выплаты
        </button>
        <transition name="fade" tag="div">
          <Modal v-if="showModal" @close="showModal = false" />
        </transition>

        <div class="stats">
          <div class="stat">
            <div class="score">{{ revshare.registrations_count }}</div>
            <div class="description">
              Приглашено <br />
              всего
            </div>
          </div>

          <div class="stat">
            <div class="score">{{ revshare.first_deposits_count }}</div>
            <div class="description">
              Первых депозитов <br />
              всего
            </div>
          </div>

          <div class="stat">
            <div class="score">0</div>
            <div class="description">
              Активных <br />
              игроков
            </div>
          </div>

          <div class="stat">
            <div class="score">
              {{ revshare.registrations_last_month_count }}
            </div>
            <div class="description">
              Приглашено <br />
              за месяц
            </div>
          </div>

          <div class="stat">
            <div class="score">
              {{ revshare.first_deposits_last_month_count }}
            </div>
            <div class="description">
              Первых депозитов <br />
              за месяц
            </div>
          </div>

          <div class="stat">
            <div class="score">
              {{ convertAmount(revshare.payoutSum, "rub") }}
              {{ icons["rub"] }}
            </div>
            <div class="description">
              Заработано <br />
              всего
            </div>
          </div>
        </div>

        <div class="history">
          <div class="title">История выплат</div>
          <div class="table">
            <div class="head">
              <span class="t">Дата</span>
              <span class="t">Сумма выплаты</span>
              <span class="t">Статус</span>
            </div>
            <transition-group name="fade" tag="div">
              <div
                v-for="payout in revshare.payouts"
                :key="payout.id"
                class="data"
              >
                <span class="t">
                  {{ payout.created_at | formatDate("yyyy-MM-dd") }}
                </span>
                <span class="t">
                  {{ convertAmount(payout.amount, payout.currency) }}
                  {{ icons[payout.currency] }}
                </span>
                <span class="t text-success">Успешно</span>
                <span class="badge">
                  <img
                    alt="stats"
                    src="@/assets/icons/analytics-referals.svg"
                  />
                </span>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="sidebar">
        <div class="form-group">
          <div class="title">Ваша ссылка для привлечения рефералов</div>
          <div class="group">
            <input type="text" v-model="revshare.link" disabled />
            <span class="badge" @click="copy(revshare.link)">
              <img alt="copy" src="@/assets/icons/referals-bookmarks.svg" />
            </span>
          </div>
        </div>

        <!-- <button class="primary">Мои компании</button> -->

        <div class="deposits">
          <div
            v-for="(deposits, chance) in lvls"
            :key="chance"
            :class="{ active: chance == revshare.commission }"
            class="deposit"
          >
            <span class="badge">{{ chance }}%</span>
            {{ deposits }}
            <span class="text" v-if="deposits === 1">первоначальный</span>
            <span class="text" v-else>первоначальных</span>
            депозит в месяц
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { format, startOfMonth, endOfMonth } from "date-fns";
import Modal from "@/components/Profile/Referals/Modal";
export default {
  data() {
    return {
      showModal: false,
      profit: "???",
      revshare: {
        commission: 5,
        first_deposits_count: 0,
        first_deposits_last_month_count: 0,
        registrations_last_month_count: 0,
        registrations_count: 0,
        payoutSum: 0,
        link: "...",
        payouts: [],
      },
      lvls: {
        5: 1,
        10: 5,
        15: 20,
        20: 50,
        25: 75,
        30: 100,
        35: 200,
        40: 400,
        45: 700,
        50: 1000,
      },
    };
  },
  computed: {
    ...mapState("user", ["icons"]),
    ...mapGetters("user", ["convertAmount"]),
    progress() {
      let lvl = this.revshare.commission;
      let nextlvl = lvl + 5;
      if (!this.lvls[nextlvl]) nextlvl = lvl;
      let nextchance = this.lvls[nextlvl];
      let current = this.revshare.first_deposits_last_month_count;
      let percent = (current / nextchance) * 100;
      let start = format(startOfMonth(new Date()), "yyyy-MM-dd");

      const finish_at = format(endOfMonth(new Date()), "yyyy-MM-dd");

      return { nextlvl, nextchance, current, percent, start, finish_at };
    },
  },
  async created() {
    try {
      let { data } = await this.$http.get("affiliates/init");
      let { campaigns } = data.response;

      this.revshare = campaigns.find(
        (campaign) => campaign.type === "revshare"
      );

      this.revshare.commission = parseInt(this.revshare.commission);
      await this.getProfit();
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },

  methods: {
    async getProfit() {
      let { data } = await this.$http.get("affiliates/profit");
      this.profit = this.convertAmount(data?.response?.profit || 0, "rub");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success("Успешно скопировано");
    },
  },

  components: {
    Modal,
  },

  filters: {
    formatDate(value, type) {
      return format(new Date(value.replace(" ", "T")), type);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  background: linear-gradient(90deg, #579824 0%, #a5e673 100%);
  border-radius: 6px;
  height: 90px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 15px rgba(110, 175, 59, 0.25);
  justify-content: space-between;
  width: calc(100% - 335px - 15px);
  @media (max-width: 768px) {
    width: 100%;
  }
  .left {
    display: flex;
    align-items: center;
    .badge {
      margin: 0 25px;
      width: 45px;
      background-color: rgba(white, 0.35);
      height: 45px;
      box-shadow: 0 0 0 10px rgba(white, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      font-weight: 500;
      font-size: 18px;
    }
    .title {
      color: white;
      font-size: 16px;
      font-weight: 500;
      width: calc(100% - 45px - (25px * 2));
    }
  }
  padding: 0 20px 0 10px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .box {
    height: 90px;
    padding: 17px 25px;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &.yellow {
      background: linear-gradient(270deg, #ffcf25 20.33%, #e39600 97.1%);
      box-shadow: 0px 15px 15px rgba(238, 172, 14, 0.25);
      width: 245px;
    }
    &.green {
      background: linear-gradient(270deg, #9ddd6b 20.33%, #579824 97.1%);
      box-shadow: 0px 15px 15px rgba(110, 175, 59, 0.25);
      width: 245px;
    }
    &.blue {
      background: linear-gradient(270deg, #59cdff 0%, #8e1eff 100%);
      box-shadow: 0px 15px 15px rgba(121, 99, 255, 0.25);
      width: 335px;
    }
    // font-weight: 500;
    .title {
      font-size: 18px;
      color: white;
      font-weight: 500;
      margin-bottom: 3px;
    }
    .description {
      font-size: 14px;
      color: rgba(white, 0.5);
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.main {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .sidebar {
    width: 335px;
    .deposits {
      .deposit {
        background: #f8fafc;
        border: 1px solid rgba(201, 211, 221, 0.45);
        border-radius: 6px;
        display: flex;
        padding: 10px;
        align-items: center;
        margin-bottom: 15px;
        color: #abb6c1;
        font-size: 13px;
        .badge {
          background: #0085ff;
          box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          margin-right: 10px;
          height: 40px;
          font-size: 14px;
          color: white;
        }
        span.text {
          margin: 0 3px;
        }
        &.active {
          border: 2px solid #59c503;
          .badge {
            background: #59c503;
            box-shadow: 0px 15px 35px rgba(89, 197, 3, 0.25);
          }
        }
      }
    }
    .form-group {
      .title {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 14px;
      }
      .group {
        background: #f8fafc;
        border-radius: 6px;
        display: flex;
        padding: 8px;
        height: 50px;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: center;
        input {
          color: #63788d;
          padding-left: 12px;
          font-size: 15px;
          width: 250px;
          background-color: transparent;
        }
        .badge {
          width: 35px;
          height: 35px;
          background: #0085ff;
          box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 200ms ease;
          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content {
    width: calc(100% - 335px - 15px);
    .progress-bar {
      margin-bottom: 15px;
      .progress {
        background: #f7f7fb;
        border-radius: 6px;
        height: 35px;
        position: relative;
        .line {
          background: #0085ff;
          border-radius: 6px;
          height: 35px;
          width: 50%;
          min-width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .finish {
          position: absolute;
          right: 15px;
          color: #b5b9d0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
        }
      }
      .title {
        font-size: 18px;
        color: #000000;
        margin-bottom: 5px;
        font-weight: 500;
      }
      .description {
        color: #a3afc9;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .stat {
        border-radius: 6px;
        margin-bottom: 15px;
        width: 170px;
        background: #f8fafc;
        padding: 15px;
        border: 1px solid rgba(201, 211, 221, 0.45);
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .score {
          font-size: 28px;
          font-weight: 500;
          margin-bottom: 5px;
          color: black;
        }
        .description {
          color: #b1b8bf;
          font-size: 13px;
        }
      }
    }
    .history {
      .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 15px;
      }
      .table {
        .head {
          border: 1px solid rgba(#e7eef5, 0.45);
          border-radius: 6px;
          display: flex;
          height: 40px;
          align-items: center;
          margin-bottom: 15px;
          span.t {
            font-size: 14px;
            color: #b1b8bf;
            width: calc(100% / 3 - (44px / 3));
            text-align: center;
          }
        }
        .data {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          border: 1px solid #e7eef5;
          border-radius: 6px;
          height: 50px;
          span.t {
            width: calc(100% / 3 - (44px / 3));
            text-align: center;
            color: #000000;
            font-size: 14px;
            &.text-success {
              color: #59c503;
            }
          }
          span.badge {
            width: 34px;
            height: 34px;
            background: #0085ff;
            border-radius: 6px;
            display: flex;
            align-items: center;
            margin-right: 10px;
            justify-content: center;
          }
        }
      }
    }
  }
  button.primary {
    background: #0085ff;
    display: block;
    margin-bottom: 15px;
    border-radius: 6px;
    height: 50px;
    color: white;
    width: 100%;
    text-transform: uppercase;
    transition: 200ms ease;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
    }
    .content {
      width: 100%;
      .stats {
        .stat {
          width: calc(100% / 2 - 10px);
        }
      }
    }
  }
}
</style>
